

export default function RequiredFieldError(props) {
    const { message = "" } = props
  return (
    <span className="pt-2 text-red-700 text-sm">
      {message}
    </span>
  );
}
