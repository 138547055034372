export default function Backdrop(props) {
  const { showBack, align = "default", fullScreen = false, fullModalBodyContent = false } = props;
  const contentAlign = {
    default: `justify-center ${fullModalBodyContent ? '':'items-end'} md:items-start`,
    right: `${fullModalBodyContent ? '':'items-end'} justify-end`,
    center: 'justify-center items-center'
  };
  return (
    <div
      className={`!z-[100] fixed overflow-x-scroll inset-0 w-full h-full backdrop-blur-sm bg-black/25 md:bg-black/50 transition-all flex md:pt-[10vh] pb-0 ${
        fullScreen ? "md:!py-0" : "md:py-8"
      } ${contentAlign[align]} ${showBack ? "opacity-1" : "opacity-0"}`}
    >
      {props.children}
    </div>
  );
}
