import { useTranslation } from "react-i18next";
export default function RequestSubmitted(props) {
  const { folio = "####" } = props;
  const { t } = useTranslation(); 
  return (
    <div className="w-full flex justify-center">
      <div className="max-w-prose w-full bg-slate-100 rounded-t-lg overflow-hidden drop-shadow-lg">
        <div className=" bg-gradient-to-b from-green-500 to-green-600 h-8"></div>
        <div className="p-8 text-center">
          <p className="text-lg text-center pb-4">
            {t("transfer_notification.msg_success")}
          </p>
          <div className="pb-8">
            <span className="text-slate-500">Folio: </span>
            <span className="text-xl font-bold text-slate-600 tracking-wider">
              {folio}
            </span>
          </div>
          <div className="pb-8">
            <p className="text-lg">{t("transfer_notification.lbl_thanks")}</p>
          </div>
          <div className="flex justify-end">
            <a
              href="https://miahotelsandresorts.com/"
              target="_self"
              rel="noreferrer"
              className="w-full md:w-fit bg-slate-700 text-white rounded-lg drop-shadow-lg uppercase font-bold px-4 py-4 tracking-wider"
            >
              {t("transfer_notification.lbl_accept")}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
