import { useState } from "react";
import Modal from "../../Utils/Modal/Modal";
import { useTranslation } from "react-i18next";
export default function PopUpTerms(props) {
  const { t } = useTranslation();
  const { hotel } = props;
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <button
        onClick={() => setShowModal(true)}
        className="text-blue-600 text-sm md:text-xs underline underline-offset-2"
      >
        {t("terms.title")}
      </button>
      <Modal showModal={showModal} setShowModal={setShowModal}>
        <h2 className="font-bold pb-4 text-center">{t("terms.title")}</h2>
        <ul className="text-sm mia-list">
          {hotel === "miareefim" && (
            <>
              <li>{t("terms.miareefim.lbl_01")}</li>
              <li>{t("terms.miareefim.lbl_02")}</li>
              <li>{t("terms.miareefim.lbl_03")}</li>
              <li>{t("terms.miareefim.lbl_04")}</li>
              <li>{t("terms.miareefim.lbl_05")}</li>
              <li>{t("terms.miareefim.lbl_06")}</li>
            </>
          )}
          {hotel === "miabacalar" && (
            <>
              <li>{t("terms.miabacalar.lbl_01")}</li>
              <li>{t("terms.miabacalar.lbl_02")}</li>
              <li>{t("terms.miabacalar.lbl_03")}</li>
              <li>{t("terms.miabacalar.lbl_04")}</li>
              <li>{t("terms.miabacalar.lbl_05")}</li>
            </>
          )}
        </ul>
      </Modal>
    </>
  );
}
