import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL

const verifyTransportation = (reference) => {
  return axios.get(`${API_URL}/redeem-transportation/${reference}`)
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })
}

const sendRequestTransportation = (params) => {
  return axios
  .post(`${API_URL}/redeem-transportation`, params)
  .then(response => {
      return response
  })
  .catch(error => {
      return error.response
  })
}

export default {
    verifyTransportation,
    sendRequestTransportation
}