import logo from "./logo.svg";
import "./App.css";
import { useTranslation } from "react-i18next";
import LocaleSwitcher from "./i18n/LocaleSwitcher";
import Redeem from "./components/Transportation/Redeem";
import { Toaster } from "react-hot-toast";
import {
  BrowserRouter,
  createBrowserRouter,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
function App() {
  const { t } = useTranslation();
  return (
    <>
      <div>
        <Toaster />
      </div>
      <BrowserRouter>
        <Routes>
          <Route path="/redeem" element={<Redeem />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
