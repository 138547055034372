import { useState } from "react";
import { useTranslation } from "react-i18next";
export default function CaptureReference(props) {
  const { setReference } = props;
  const [referenceInputValue, setReferenceInputValue] = useState("");
  const { t } = useTranslation();
  const handleCaptureReference = () => {
    setReference(referenceInputValue)
  };
  return (
    <div className="w-full flex justify-center">
      <div className="max-w-prose w-full bg-slate-100 rounded-t-lg overflow-hidden drop-shadow-lg">
        <div className=" bg-gradient-to-b from-slate-500 to-slate-600 h-8"></div>
        <div className="p-8">
          <div className="flex justify-center pb-4 md:pb-8">
            <img
              src="/logo-hotelesmia.png"
              alt="MÍA Hotels And Resorts"
              width={120}
              height={107}
            />
          </div>
          <div className="pb-8 flex flex-col">
            <label
              htmlFor="capture_reference"
              className="text-sm font-bold pb-1"
            >
              {t("capture_reference.enter_label")}
            </label>
            <input
              name="capture_reference"
              id="capture_reference"
              type="text"
              placeholder={t("capture_reference.input_plhold")}
              className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg block w-full py-1 px-2"
              onChange={(e) => setReferenceInputValue(e.target.value)}
            />
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              onClick={() => handleCaptureReference()}
              className="transition-all w-full md:w-fit bg-slate-700 text-white rounded-lg drop-shadow-lg uppercase font-bold px-4 py-2 tracking-wider hover:bg-slate-900"
            >
              {t("capture_reference.submit")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
