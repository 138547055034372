import { FaTimes } from "react-icons/fa";
export default function Close(props) {
  const { disableClose, action, theme = "default" } = props;
  const colorsStyle = {
    "default": "bg-white text-primary  hover:bg-slate-700 hover:text-white",
    "club": "bg-club-font text-club-dark  hover:bg-club-dark-altern hover:text-club-font"
  }
  return (
    <>
      {!disableClose && (
        <div className="flex justify-end --pr-4 --pt-4">
          <button
            onClick={() => action(false)}
            className={`transition-all drop-shadow-lg border p-2 rounded-full ${colorsStyle[theme]}`}
          >
            <FaTimes className="w-4 h-4" />
          </button>
        </div>
      )}
    </>
  );
}
