import ModalContext from "../context/ModalContext"
export default function ModalProvider(props) {
    const { setShowModal } = props
    const handleShowModal = (action) => {
        if (action) {
            document.documentElement.style.overflow = 'hidden';
        }
        else {
            document.documentElement.style.overflow = '';
        }
        setShowModal(action)
    }
    return (
        <ModalContext.Provider value={{ handleShowModal }}>
            {props.children}
        </ModalContext.Provider>
    )
}