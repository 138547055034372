"use client";
import { createPortal } from "react-dom";
import { useEffect, useState, useRef } from "react";
import ModalProvider from "../../../provider/ModalProvider";
import { useOutsideClick } from "../../../custom_hooks/useOutsideClick";
import Backdrop from "./Backdrop";
import Close from "./Close";
export default function Modal(props) {
  const {
    showModal = false,
    setShowModal,
    disableClose = false,
    size = "default",
    align = "default",    
    fullScreen = false,
    theme = "default"
  } = props;
  
  const modalBodyRef = useRef(null)
  const [generate, setGenerate] = useState(false);
  const [showBack, setShowBack] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [fullModalBodyContent, setFullModalBodyContent] = useState(false)
  const modalFullScreen =  "h-[90vh] md:min-h-[100vh] md:!rounded-none"
  const modalHeight = `h-fit ${fullScreen ? modalFullScreen : "min:h-[50vh]"}`
  const modalSize = {
    default: "md:w-[500px]",
    sm: "md:w-[300px]",
    lg: "md:w-[750px]",
    xl: "md:w-[1140px]"
    
  };
  const directionRevealMobile = "place-content-end translate-y-0";
  const directionDisabledMobile = "translate-y-full"
  const directionRevealStyle = {
    default: "translate-y-0  md:h-fit md:opacity-100",
    right: "translate-y-0 md:translate-y-0 md:translate-x-0",
  };
  const directionDisabledStyle = {
    default: "translate-y-full md:h-0 md:opacity-0",
    right: "translate-y-full md:translate-y-0 md:translate-x-full",
  };
  const rounded = `${align === 'center' ? '':'rounded-t-3xl'} md:rounded-lg`
  const modalBackground = "bg-white"
  const modalContentStyle = `mia-modal-content px-8 pt-4 pb-8 flex flex-col ${rounded}  overflow-hidden relative md:position-unset ${modalHeight} ${modalSize[size]} ${modalBackground} drop-shadow-lg`;
  useEffect(() => {
    if (showModal) {
      document.documentElement.style.overflow = "hidden";
      setGenerate(showModal);
    }
    if (!showModal) {
      document.documentElement.style.overflow = "";
      setTimeout(() => {
        setGenerate(false);
      }, 500);
    }
    const timerBack = setTimeout(() => {
      setShowBack(showModal);
    }, 100);
    const timer = setTimeout(() => {
      setShowContent(showModal);
    }, 300);

    return () => {
      clearTimeout(timer);
      clearTimeout(timerBack);
    };
  }, [showModal]);

  const outSideClickRef = useOutsideClick(() => {
    setShowModal(false);
  });
  useEffect(()=>{
    const modalContentHeight = modalBodyRef?.current?.offsetHeight
    if(modalContentHeight !== undefined){
      setFullModalBodyContent((modalBodyRef?.current?.offsetHeight + 64) > window.innerHeight)
    }
    
  }, [showContent])
  return (
    <ModalProvider setShowModal={setShowModal}>
      {generate &&
        createPortal(
          <Backdrop showBack={showBack} align={align} fullScreen={fullScreen} fullModalBodyContent={fullModalBodyContent}>
            <div
              className={`mia-modal h-fit flex flex-col transition-all duration-500 ${
                showContent ? `${directionRevealStyle[align]}` : `${directionDisabledStyle[align]}`
              } md:w-fit w-full --max-w-2xl --md:h-full --right-0`}
            >
              <div ref={outSideClickRef} className={modalContentStyle}>
                <Close disableClose={disableClose} action={setShowModal} theme={theme} />
                <div className="h-full w-full overflow-auto pt-4" ref={modalBodyRef}>
                  <div className="w-full h-full">{props.children}</div>
                </div>
              </div>
            </div>
          </Backdrop>,
          document.body
        )}
    </ModalProvider>
  );
}
