import { useState, useEffect, useMemo, useRef } from "react";
import Layout from "../Utils/Layout";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import TransferService from "../../services/transfer.service";
import Select from "react-select";
import { useLocation } from "react-router-dom";
import RequiredFieldError from "../Utils/RequiredFieldError";
import DotsLoading from "../Utils/DotsLoading";
import { toast } from "react-hot-toast";
import RequestSubmitted from "./RequestSubmitted";
import LoadingDataSkeleton from "./LoadingDataSkeleton";
import ReCAPTCHA from "react-google-recaptcha";
import CaptureReference from "./CaptureReference";
import PopUpTerms from "./TermsAndConditions/PopUpTerms";
function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export default function Redeem() {
  let query = useQuery();
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState("en");
  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(false);
  const [reference, setReference] = useState(query.get("reference"));
  const [transfer, setTransfer] = useState({});
  const [hotels, setHotels] = useState([]);
  const [airports, setAirports] = useState([]);
  const [transferPlanTypes, setTransferPlanTypes] = useState([]);
  const [airlines, setAirlines] = useState([]);
  const [serviceTypeSelected, setServiceTypeSelected] = useState(false);
  const [reqSubmitted, setReqSubmitted] = useState(false);
  const recaptchaRef = useRef();
  const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHASITEKEY;
  const [showRecaptcha, setShowRecaptcha] = useState(false);
  const [manualCapture, setManualCapture] = useState(false)
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues
  } = useForm();

  useEffect(() => {
    if (reference === null) {
      setManualCapture(true)
    } else {
      verifyTransportation()
    }
  }, []);

  useEffect(() => {    
    if(reference && manualCapture){
      verifyTransportation()
    } 
  }, [reference])



  const verifyTransportation = () => {
    TransferService.verifyTransportation(reference).then((response) => {
      if (response?.status === 200) {
        setTransfer(response?.data ?? {});
        //Verificamos existencia
        if (response?.data?.exist ?? false) {
          setReqSubmitted(true);
          return;
        }
        initForm(response?.data);
        setLoadingData(false);
      }
      else if(response?.status === 403){
        setReference(null)
        setManualCapture(true)
        toast.error(t("transfer_form.msg_invalid_req"))
      } else {
        setReference(null)
        setManualCapture(true)
        toast.error(t("transfer_form.msg_invalid_id"));
      }
    });
  }

  const initForm = (transfer) => {
    //Inicializamos selects
    if (transfer?.language ?? false) {
      i18n.changeLanguage(transfer?.language ?? "en");
      setLanguage(transfer?.language ?? "en");
    }
    setHotels([transfer?.hotel ?? {}]);
    setAirports([transfer?.airport ?? {}]);
    setTransferPlanTypes(transfer?.transferPlanTypes ?? []);
    setAirlines(transfer?.airlines ?? []);
    reset({
      reference: transfer.reference,
      name: transfer.name,
      lastName: transfer.lastName,
      email: transfer.email,
      hotel: transfer?.hotel?.code ?? "",
      airport: transfer?.airport?.iata ?? "",
      planType: transfer?.transferPlanTypes[0].code ?? "",
    });
    setServiceTypeSelected(transfer?.transferPlanTypes[0].code ?? "");
  };

  const handleInputChange = (e) => {
    const input = e.target.value;
    let validInput = input.replace(/[^\d-]/g, "").replace(/(?!^)-/g, "");
    validInput = validInput <= 0 ? 1 : validInput >= 5 ? 4 : validInput;
    setValue("pax", validInput);
  };

  const validateInput = (value) => {
    const num = Number(value);
    if (isNaN(num) || num < 1 || num > 4) {
      return t("transfer_form.msg_field_required");
    }
    return true;
  };

  const handlePlanType = (e) => {
    setServiceTypeSelected(e.target.value);
  };

  const submit = (props) => {
    const recaptchaValue = recaptchaRef.current.getValue();
    if (recaptchaValue.trim() === "") {
      toast.error(t("transfer_form.lbl_verify_fields"));
      return;
    }

    const {
      reference,
      hotel,
      name,
      lastName,
      email,
      phone,
      airport,
      planType,
      pax,
      arrivalDate = null,
      arrivalTime,
      arrivalFlightNumber,
      departureDate = null,
      departureTime,
      departureFlightNumber,
      additionalComments,
    } = props;
    const params = {
      captcha: recaptchaValue,
      reference,
      hotel,
      name,
      lastName,
      email,
      phone,
      airport,
      transferType: planType,
      pax,
      arrivalAirline: props?.arrivalAirline?.value ?? null,
      arrivalDate: arrivalDate === "" ? null : arrivalDate,
      arrivalTime: arrivalTime === "" ? null : arrivalTime,
      arrivalFlightNumber,
      departureAirline: props?.departureAirline?.value ?? null,
      departureDate: departureDate === "" ? null : departureDate,
      departureTime: departureTime === "" ? null : departureTime,
      departureFlightNumber,
      additionalComments,
    };
    setLoading(true);
    TransferService.sendRequestTransportation(params).then((response) => {
      if (response?.status === 201 ?? false) {
        toast.success(t("transfer_form.msg_success"));
        setTransfer(response?.data ?? {});
        setReqSubmitted(true);
      } else {
        toast.error(t("transfer_form.msg_error"));
      }
      setLoading(false);
    });
    return null;
  };

  return (
    <Layout>
      {reqSubmitted ? (
        <RequestSubmitted folio={transfer?.folio ?? ""} />
      ) : (
        reference ? (
          <div className="bg-slate-100 px-4 py-8 md:px-8 md:py-8 rounded-lg drop-shadow-lg">
            {loadingData ? (
              <LoadingDataSkeleton />
            ) : (
              <div>
                <div className="flex justify-center">
                  <img
                    src="/logo-hotelesmia.png"
                    alt="MÍA Hotels And Resorts"
                    width={120}
                    height={107}
                  />
                </div>
                <div className="pb-4">
                  <h1 className="text-lg font-bold">{t("redeem.title")}</h1>
                  <p className=" text-slate-500 pb-1">{t("redeem.subtitle")}</p>
                  <PopUpTerms hotel={getValues("hotel")} />
                </div>
                <form id="transferForm" onSubmit={handleSubmit(submit)}>
                  <section id="transfer-info" className="pb-4">
                    <div className="relative gap-4 md:gap-8 pb-4 grid grid-cols-1 md:grid-cols-2">
                      <div className="flex flex-col">
                        <label htmlFor="" className="text-sm font-bold pb-1">
                          {t("transfer_form.lbl_reference")}
                        </label>
                        <input
                          disabled
                          type="text"
                          className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg block w-full py-1 px-2 disabled:bg-slate-200 font-bold"
                          {...register("reference", {
                            required: t("transfer_form.msg_field_required"),
                          })}
                        />
                      </div>
                      <div className="flex flex-col">
                        <label htmlFor="" className="text-sm font-bold pb-1">
                          Hotel
                        </label>
                        <select
                          disabled
                          name=""
                          id=""
                          className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg block w-full py-1 px-2 h-[34px] disabled:bg-slate-200 font-bold text-sm"
                          {...register("hotel")}
                        >
                          <option value="null">...</option>
                          {hotels.map((hotel, iHotel) => {
                            return (
                              <option
                                key={`hotel_list_item_${iHotel}`}
                                value={hotel.code}
                              >
                                {hotel.description}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="relative gap-4 md:gap-8 pb-4 grid grid-cols-1 md:grid-cols-2">
                      <div className="flex flex-col">
                        <label htmlFor="" className="text-sm font-bold pb-1">
                          {t("transfer_form.lbl_name")}
                        </label>
                        <input
                          type="text"
                          className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg block w-full py-1 px-2"
                          {...register("name", {
                            required: t("transfer_form.msg_field_required"),
                          })}
                        />
                        {errors.name && (
                          <RequiredFieldError message={errors.name.message} />
                        )}
                      </div>
                      <div className="flex flex-col">
                        <label htmlFor="" className="text-sm font-bold pb-1">
                          {t("transfer_form.lbl_lastName")}
                        </label>
                        <input
                          type="text"
                          className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg block w-full py-1 px-2"
                          {...register("lastName", {
                            required: t("transfer_form.msg_field_required"),
                          })}
                        />
                        {errors.lastName && (
                          <RequiredFieldError
                            message={errors.lastName.message}
                          />
                        )}
                      </div>
                    </div>
                    <div className="relative gap-4 md:gap-8 pb-4 grid grid-cols-1 md:grid-cols-2">
                      <div className="flex flex-col">
                        <label htmlFor="" className="text-sm font-bold pb-1">
                          Email
                        </label>
                        <input
                          type="text"
                          placeholder="Example: contacto@hotelesmia.com"
                          className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg block w-full py-1 px-2 placeholder:text-sm"
                          {...register("email", {
                            required: t("transfer_form.msg_field_required"),
                          })}
                        />
                        {errors.email && (
                          <RequiredFieldError message={errors.email.message} />
                        )}
                      </div>
                      <div className="flex flex-col">
                        <label htmlFor="" className="text-sm font-bold pb-1">
                          {t("transfer_form.lbl_phone")}
                        </label>
                        <input
                          onFocus={(e) => setShowRecaptcha(true)}
                          type="text"
                          placeholder={t("transfer_form.lbl_ex_phone")}
                          className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg block w-full py-1 px-2 placeholder:text-sm"
                          {...register("phone", {
                            required: t("transfer_form.msg_field_required"),
                          })}
                        />
                        {errors.phone && (
                          <RequiredFieldError message={errors.phone.message} />
                        )}
                      </div>
                    </div>
                  </section>
                  <section
                    id="service-details"
                    className="border-t border-slate-300 py-4"
                  >
                    <div className="pb-4">
                      <h2 className="text-lg font-bold uppercase">
                        {t("transfer_form.title_service_det")}
                      </h2>
                    </div>
                    <div className="relative gap-4 md:gap-8 pb-4 grid grid-cols-1 md:grid-cols-3">
                      <div className="flex flex-col">
                        <label htmlFor="" className="text-sm font-bold pb-1">
                          {t("transfer_form.lbl_airport")}
                        </label>
                        <select
                          disabled
                          name="airport"
                          id="airport"
                          className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg block w-full py-1 px-2 h-[34px] disabled:bg-slate-200 font-bold text-sm"
                          {...register("airport")}
                        >
                          <option value="null">...</option>
                          {airports.map((airport, iAirport) => {
                            return (
                              <option
                                key={`hotel_list_item_${iAirport}`}
                                value={airport.iata}
                              >
                                {airport.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="flex flex-col">
                        <label htmlFor="" className="text-sm font-bold pb-1">
                          {t("transfer_form.lbl_service_type")}
                        </label>
                        <select
                          name="planType"
                          id="planType"
                          className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg block w-full py-1 px-2 h-[34px]"
                          {...register("planType", {
                            onChange: handlePlanType,
                            required: t("transfer_form.msg_field_required"),
                          })}
                        >
                          {transferPlanTypes.map((type, iType) => {
                            return (
                              <option
                                key={`hotel_list_item_${iType}`}
                                value={type.code}
                              >
                                {type.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="flex flex-col">
                        <label htmlFor="" className="text-sm font-bold pb-1">
                          {t("transfer_form.lbl_pax")}
                        </label>
                        <input
                          type="number"
                          min="0"
                          max="4"
                          className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg block w-full py-1 px-2"
                          {...register("pax", {
                            onChange: handleInputChange,
                            validate: validateInput,
                            required: t("transfer_form.msg_field_required"),
                          })}
                        />
                        {errors.pax && (
                          <RequiredFieldError message={errors.pax.message} />
                        )}
                      </div>
                    </div>
                  </section>
                  {["roundtrip", "arrival", "arrival_ferry"].includes(serviceTypeSelected) && (
                    <section
                      id="arrival-info"
                      className="border-t border-slate-300 py-4"
                    >
                      <div className="pb-4">
                        <h2 className="text-lg font-bold uppercase">
                          {t("transfer_form.title_arv_det")}
                        </h2>
                      </div>
                      <div className="relative gap-4 md:gap-8 pb-4 grid grid-cols-1 md:grid-cols-4">
                        <div className="flex flex-col">
                          <label htmlFor="" className="text-sm font-bold pb-1">
                            {t("transfer_form.lbl_airline")}
                          </label>
                          <Controller
                            name="arrivalAirline"
                            control={control}
                            rules={{
                              required: t("transfer_form.msg_field_required"),
                            }}
                            render={({ field }) => (
                              <Select
                                className="select"
                                placeholder={t(
                                  "transfer_form.lbl_select_airline"
                                )}
                                isLoading={airlines.length === 0}
                                options={airlines.map((i) => {
                                  return { value: i.id, label: i.name };
                                })}
                                isSearchable={true}
                                {...field}
                              />
                            )}
                          />
                          {errors.arrivalAirline && (
                            <RequiredFieldError
                              message={errors.arrivalAirline.message}
                            />
                          )}
                        </div>
                        <div className="flex flex-col">
                          <label htmlFor="" className="text-sm font-bold pb-1">
                            {t("transfer_form.lbl_arv_dt")}
                          </label>
                          <input
                            type="date"
                            className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg block w-full py-1 px-2"
                            {...register("arrivalDate", {
                              required: t("transfer_form.msg_field_required"),
                            })}
                          />
                          {errors.arrivalDate && (
                            <RequiredFieldError
                              message={errors.arrivalDate.message}
                            />
                          )}
                        </div>
                        <div className="flex flex-col">
                          <label htmlFor="" className="text-sm font-bold pb-1">
                            {t("transfer_form.lbl_arv_time")}
                          </label>
                          <input
                            type="time"
                            className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg block w-full py-1 px-2"
                            {...register("arrivalTime", {
                              required: t("transfer_form.msg_field_required"),
                            })}
                          />
                          {errors.arrivalTime && (
                            <RequiredFieldError
                              message={errors.arrivalTime.message}
                            />
                          )}
                        </div>
                        <div className="flex flex-col">
                          <label htmlFor="" className="text-sm font-bold pb-1">
                            {t("transfer_form.lbl_flg_num")}
                          </label>
                          <input
                            type="text"
                            placeholder={t("transfer_form.lbl_ex_flight_num")}
                            className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg block w-full py-1 px-2 placeholder:text-sm placeholder:italic"
                            {...register("arrivalFlightNumber", {
                              required: t("transfer_form.msg_field_required"),
                            })}
                          />
                          {errors.arrivalFlightNumber && (
                            <RequiredFieldError
                              message={errors.arrivalFlightNumber.message}
                            />
                          )}
                        </div>
                      </div>
                    </section>
                  )}
                  {["roundtrip", "departure", "departure_ferry"].includes(serviceTypeSelected) && (
                    <section
                      id="departure-info"
                      className="border-t border-slate-300 py-4"
                    >
                      <div className="pb-4">
                        <h2 className="text-lg font-bold uppercase">
                          {t("transfer_form.title_dept_det")}
                        </h2>
                      </div>
                      <div className="relative gap-4 md:gap-8 pb-4 grid grid-cols-1 md:grid-cols-4">
                        <div className="flex flex-col">
                          <label htmlFor="" className="text-sm font-bold pb-1">
                            {t("transfer_form.lbl_airline")}
                          </label>
                          <Controller
                            name="departureAirline"
                            control={control}
                            rules={{
                              required: t("transfer_form.msg_field_required"),
                            }}
                            render={({ field }) => (
                              <Select
                                className="select"
                                placeholder={t(
                                  "transfer_form.lbl_select_airline"
                                )}
                                isLoading={airlines.length === 0}
                                options={airlines.map((i) => {
                                  return { value: i.id, label: i.name };
                                })}
                                isSearchable={true}
                                {...field}
                              />
                            )}
                          />
                          {errors.departureAirline && (
                            <RequiredFieldError
                              message={errors.departureAirline.message}
                            />
                          )}
                        </div>
                        <div className="flex flex-col">
                          <label htmlFor="" className="text-sm font-bold pb-1">
                            {t("transfer_form.lbl_dept_dt")}
                          </label>
                          <input
                            type="date"
                            className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg block w-full py-1 px-2"
                            {...register("departureDate", {
                              required: t("transfer_form.msg_field_required"),
                            })}
                          />
                          {errors.departureDate && (
                            <RequiredFieldError
                              message={errors.departureDate.message}
                            />
                          )}
                        </div>
                        <div className="flex flex-col">
                          <label htmlFor="" className="text-sm font-bold pb-1">
                            {t("transfer_form.lbl_dept_time")}
                          </label>
                          <input
                            type="time"
                            className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg block w-full py-1 px-2"
                            {...register("departureTime", {
                              required: t("transfer_form.msg_field_required"),
                            })}
                          />
                          {errors.departureTime && (
                            <RequiredFieldError
                              message={errors.departureTime.message}
                            />
                          )}
                        </div>
                        <div className="flex flex-col">
                          <label htmlFor="" className="text-sm font-bold pb-1">
                            {t("transfer_form.lbl_flg_num")}
                          </label>
                          <input
                            type="text"
                            placeholder={t("transfer_form.lbl_ex_flight_num")}
                            className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg block w-full py-1 px-2 placeholder:text-sm placeholder:italic"
                            {...register("departureFlightNumber", {
                              required: t("transfer_form.msg_field_required"),
                            })}
                          />
                          {errors.departureFlightNumber && (
                            <RequiredFieldError
                              message={errors.departureFlightNumber.message}
                            />
                          )}
                        </div>
                      </div>
                    </section>
                  )}
                  <section id="comments" className="py-4">
                    <div className="flex flex-col">
                      <label htmlFor="" className="text-sm font-bold pb-1">
                        {`${t("transfer_form.lbl_add_comments")} ${t(
                          "transfer_form.lbl_optional"
                        )}`}
                      </label>
                      <textarea
                        name="additionalComments"
                        id="additionalComments"
                        placeholder={t(
                          "transfer_form.lbl_plchold_add_comments"
                        )}
                        className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg block w-full p-2 placeholder:text-sm placeholder:italic"
                        rows={3}
                        {...register("additionalComments")}
                      ></textarea>
                    </div>
                  </section>
                  <section id="submit-section" className="pt-8">
                    {showRecaptcha && (
                      <div className="flex md:justify-end">
                        <div className="pb-4">
                          <ReCAPTCHA
                            ref={recaptchaRef}
                            hl={language}
                            sitekey={recaptchaSiteKey}
                          />
                        </div>
                      </div>
                    )}
                    <div className="flex justify-end">
                      <button
                        type="submit"
                        className={`w-full md:w-fit min-w-[200px] bg-blue-700 text-white rounded-lg drop-shadow-lg uppercase font-bold px-4 py-4 ${
                          loading ? "loading-state bg-opacity-75" : ""
                        }`}
                      >
                        {loading ? (
                          <DotsLoading />
                        ) : (
                          <span>Send Request Transfer</span>
                        )}
                      </button>
                    </div>
                  </section>
                </form>
              </div>
            )}
          </div>
        )
        :
        <CaptureReference reference={reference} setReference={setReference} />
      )}
    </Layout>
  );
}
