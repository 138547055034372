import Header from "./Header";
export default function Layout(props) {
  return (
    <div className="flex flex-row mb-32">
      <div className="container flex-1 basis-[0%]">
        <Header />
        <main>{props.children}</main>
      </div>
    </div>
  );
}
